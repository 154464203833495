import React, { useState, useEffect, useRef } from "react";
import { LoginContainer } from "./assets/LoginContainer.style";
import { Button } from "primereact/button";
import { Trans, withTranslation } from "react-i18next";
import { ToastContainer, toast, Slide } from "react-toastify";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import WaitDialog from "../components/WaitDialog";
import AuthService from "../functionalities/services/auth.service";


const emptyLoginData = {
  username: "",
  password: "",
};

const Login = ({ t }) => {

  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState(emptyLoginData);

  const handleChange = (field, value) => {
    setLoginData({ ...loginData, [field]: value });
  };

  const handleLogin = async () => {
   
    setLoading(true);

    if (loginData.username === "" || loginData.password === "") {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnLogin1" />);
      setLoginData({ ...loginData, responseCaptcha: "" });
      return;
    }

    AuthService.login(loginData).then(
      (response) => {
       

        if(response.responseCode === '103' || response.responseType === 'WARN'){
          toast.warning(<Trans i18nKey="warnInvalidLogin" />);
          setLoading(false);
        }
        else{
          setLoading(false);
          window.location.href="/landing"
        }
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
        setLoading(false);
      }
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <LoginContainer>
      <div className="header-container">
        <div className="welcome-text">{t("welcomeTo")}</div>
        <div className="register-text">{t("loginPageText")}</div>
      </div>
      <form className="input-container" onSubmit={handleSubmit}>
        
          <div className="p-float-label w-full" id="username">
            <InputText
              id="username"
              type="text"
              className="w-full p-inputtext-sm block"
              value={loginData.email}
              onChange={(e) => handleChange("username", e.target.value)}
            />
            <label htmlFor="username">{t("username")} (*)</label>
          </div>
          <div className="p-float-label">
            <Password
              id="password"
              type="text"
              className="w-full p-inputtext-sm"
              toggleMask
              feedback={false}
              value={loginData.password}
              onChange={(e) => handleChange("password", e.target.value)}
            />
            <label htmlFor="password">{t("password")} (*)</label>
          </div>
       
      </form>

      <Button
        onClick={handleLogin}
        label={t("login")}
        className="login-button mt-3"
      />

      <WaitDialog loading={loading} lang={t} />

      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </LoginContainer>
  );
};
export default withTranslation()(Login);
